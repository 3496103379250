import React from 'react';
import Canva from '../components/canva';

export default function MiniSeries() {
  return (
    <Canva
      title="Tubbles"
      url="https://www.canva.com/design/DAGZ74NkgiY/ExoHW6uW5bZRvhZQwmTrfw/view?embed"
    />
  );
}
