import React from 'react';
import { GlobalStyle } from '../components/layout';
import { Helmet } from 'react-helmet';

export default function Canva({ url, title }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Lookout Content - ${title}`}</title>
      </Helmet>
      <GlobalStyle />
      <div
        style={{
          position: 'relative',
          width: '100%',
          paddingTop: '56.25%',
          paddingBottom: 0,
          overflow: 'hidden',
          willChange: 'transform',
          boxSizing: 'border-box',
          height: '100vh',
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            border: 'none',
            padding: 0,
            margin: 0,
            display: 'block',
          }}
          src={url}
          allowFullScreen
          allow="fullscreen"
        ></iframe>
      </div>
    </>
  );
}
